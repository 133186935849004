"use server";

import {
  getProcurementOrderDetail,
  procurementOrderComplete,
} from "../apis/server_apis";
import { ProcurementOrderDetailResponse } from "../types/order";

export type ProcurementOrderProcessingRouteData = {
  details?: ProcurementOrderDetailResponse;
};

export type ProcurementProcessingProps = {
  orderId: string;
};

export const getProcurementOrderProcessingRouteData = async (
  orderId: string
): Promise<ProcurementOrderProcessingRouteData> => {
  let completeOrder = await procurementOrderComplete(orderId);
  if (completeOrder) {
    let details = await getProcurementOrderDetail({ orderId: orderId });
    return { details };
  }
  return {};
};
